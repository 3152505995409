var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right-font"},[_c('div',{staticClass:"m-2"},[(_vm.status !== '0')?_c('div',{staticClass:"m-3 w-11/12"},[_c('a-radio-group',{attrs:{"disabled":_vm.chooseValue !== '1'},on:{"change":_vm.onChange},model:{value:(_vm.optype),callback:function ($$v) {_vm.optype=$$v},expression:"optype"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("同意")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("不同意")])],1)],1):_vm._e(),(_vm.optype === 2 && _vm.billtype !== 'MBYS' && _vm.billtype !== 'BFBQ')?_c('div',{staticClass:"my-3 ml-2"},[_c('a-radio-group',{attrs:{"defaultValue":_vm.value,"size":"small","buttonStyle":"solid"},on:{"change":_vm.returnAndOver}},[_c('a-radio-button',{staticClass:"text-xs",attrs:{"value":"98"}},[_vm._v("回退")]),_c('a-radio-button',{staticClass:"text-xs",attrs:{"value":"15"}},[_vm._v("终止")])],1)],1):_vm._e(),_c('div',{staticClass:"w-12/12 m-2"},[[_c('a-textarea',{attrs:{"placeholder":"审批意见","rows":6,"disabled":_vm.chooseValue !== '1'},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})]],2)]),(
      (_vm.billtype === 'XSSQ' ||
        _vm.billtype === 'XSBX' ||
        _vm.billtype === 'XSHYBG' ||
        _vm.billtype === 'GFHX') &&
      _vm.status !== '0'
    )?_c('div',{staticClass:"m-2"},[_c('p',{staticClass:"m-3 py-3 w-12/12 item-title border-b-2"},[_vm._v("金额审批")]),(_vm.billtype === 'XSSQ' || _vm.billtype === 'XSHYBG')?_c('div',{staticClass:"m-3 w-12/12"},[_c('p',[_vm._v("批准总预算")]),_c('a-input',{ref:"upperInput",staticClass:"mt-2",attrs:{"placeholder":"输入批准金额","oninput":"this.value= this.value.match(/\\d+(\\.\\d{0,2})?/) ? this.value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''","disabled":_vm.optype === 2 || _vm.billtype === 'XSBX' || _vm.chooseValue !== '1'},on:{"focus":_vm.focus},model:{value:(_vm.budgetApproveTotal),callback:function ($$v) {_vm.budgetApproveTotal=$$v},expression:"budgetApproveTotal"}})],1):_vm._e(),(_vm.billtype === 'XSBX')?_c('div',{staticClass:"m-3 w-12/12"},[_c('p',[_vm._v(_vm._s(_vm.isBookkeeper ? '财务批准报销金额:' : '批准报销总金额 :'))]),_c('a-input',{ref:"upperInput",staticClass:"mt-2",attrs:{"placeholder":"输入批准金额","oninput":"this.value= this.value.match(/\\d+(\\.\\d{0,2})?/) ? this.value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''","disabled":_vm.optype === 2 ||
          _vm.billtype === 'XSSQ' ||
          _vm.billtype === 'XSHYBG' ||
          _vm.chooseValue !== '1'},on:{"focus":_vm.focus},model:{value:(_vm.reimAmountApprove),callback:function ($$v) {_vm.reimAmountApprove=$$v},expression:"reimAmountApprove"}})],1):_vm._e(),(_vm.isSpecialHcvReimTodo && _vm.billtype === 'GFHX')?_c('div',{staticClass:"m-3 w-12/12"},[_c('p',[_vm._v(_vm._s('批准核销金额 :'))]),_c('a-input',{ref:"upperInput",staticClass:"mt-2",attrs:{"placeholder":"输入批准核销金额","oninput":"this.value= this.value.match(/\\d+(\\.\\d{0,2})?/) ? this.value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''","disabled":_vm.optype === 2 || _vm.billtype !== 'GFHX' || _vm.chooseValue !== '1'},on:{"focus":_vm.focus},model:{value:(_vm.approvedSum),callback:function ($$v) {_vm.approvedSum=$$v},expression:"approvedSum"}})],1):_vm._e()]):_vm._e(),(_vm.status !== '0' && _vm.billtype === 'JZBG')?_c('div',{staticClass:"m-2"},[_c('p',{staticClass:"m-3 py-3 w-12/12 item-title border-b-2"},[_vm._v("讲者级别审批")]),_c('div',{staticClass:"m-3 w-12/12"},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"disabled":_vm.optype === 2 || _vm.chooseValue !== '1'},model:{value:(_vm.speakerLevel),callback:function ($$v) {_vm.speakerLevel=$$v},expression:"speakerLevel"}},_vm._l((_vm.speakerLevelColumns),function(ref){
          var text = ref.text;
          var value = ref.value;
return _c('a-select-option',{key:value,attrs:{"value":value}},[_vm._v(" "+_vm._s(text)+" ")])}),1)],1)]):_vm._e(),(_vm.isSpecialTodo && _vm.billtype === 'YGSSSQ')?_c('div',{staticClass:"m-2"},[_c('p',{staticClass:"m-3 py-3 w-12/12 item-title border-b-2"},[_vm._v(" "+_vm._s(_vm.getHouseDormitoryAppTypeTitle)+" ")]),_c('div',{staticClass:"m-3 w-12/12"},[_c('a-date-picker',{staticClass:"w-full",attrs:{"format":"YYYY-MM-DD"},on:{"change":_vm.onDateChange}})],1)]):_vm._e(),_c('div',{staticClass:"m-2 px-3 mt-4"},[_c('a-button',{attrs:{"type":"primary","disabled":_vm.chooseValue !== '1',"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" 提交 ")]),_c('a-button',{staticClass:"ml-4",attrs:{"type":"primary","disabled":_vm.chooseValue !== '1',"loading":_vm.loading},on:{"click":_vm.submitStronge}},[_vm._v(" 暂存待办 ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }